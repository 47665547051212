import React, { useState, useEffect } from "react";
import Header from "./components/Header";
import Sidebar from "./components/Sidebar";
import Slider from "./components/Slider";
import TopBar from "./components/Topbar";
import AboutSection from "./components/AboutSection";
import AwardsSection from "./components/AwardsSection";
import FamilySection from "./components/FamilySection";
import BlogSection from "./components/BlogSection";
import ContactSection from "./components/ContactSection";
import WildlifeLandscapesSection from "./components/WildlifeLandscapes";
import Footer from "./components/Footer";
import EventsPage from "./components/EventsPage";
import './css/bootstrap.min.css';
import './css/newstyle.css';
import './css/bootstrap-theme.min.css';
import './css/fontAwesome.css';
import './css/light-box.css';
import './css/owl-carousel.css';
import './css/templatemo-style.css';
import './App.css';

const App = () => {
    const [isMobile, setIsMobile] = useState(false);

    // Update `isMobile` state based on screen width
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768); // Adjust breakpoint as needed
        };

        handleResize(); // Check screen width on load
        window.addEventListener("resize", handleResize); // Add listener for window resize

        return () => window.removeEventListener("resize", handleResize); // Cleanup on unmount
    }, []);

    return (
        <div>
            {isMobile ? <TopBar /> : <Sidebar />} {/* Show TopBar on mobile, Sidebar on desktop */}
            <div className="page-content" style={{ marginTop: isMobile ? '60px' : '0' }}>
                <div id="top">
                    <Slider />
                </div>
                <div id="about">
                    <AboutSection />
                </div>
                <div id="featured">
                    <AwardsSection />
                </div>
                <div id="projects">
                    <FamilySection />
                </div>
                <div id="wildlife">
                    <WildlifeLandscapesSection />
                </div>
                <div id="contact">
                    <ContactSection />
                </div>
                <Footer />
            </div>
        </div>
    );
};

export default App;
