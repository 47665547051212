import React, { useEffect, useState } from 'react';
import { sanityFetch, urlFor } from '../sanity/client';
import PortableText from "@sanity/block-content-to-react";
import 'lightbox2/dist/css/lightbox.min.css';
import 'lightbox2/dist/js/lightbox-plus-jquery.min.js';
import './sectionstyles.css';

const WILDLIFE_QUERY = `*[_type == "wildlife"] | order(orderNumber asc) {_id, name, image, details, orderNumber}`;

const WildlifeLandscapesSection = () => {
    const [wildlife, setWildlife] = useState([]);

    useEffect(() => {
        const fetchWildlife = async () => {
            const data = await sanityFetch({ query: WILDLIFE_QUERY });
            setWildlife(data);
        };
        fetchWildlife();
    }, []);

    return (
        <section id="projects" className="content-section">
            <div className="section-heading">
                <h1><em>Wildlife/Landscapes</em></h1>
            </div>
            <div className="section-content">
                <div className="masonry">
                    {wildlife.map((animal) => (
                        <div
                            className="item"
                            key={animal._id}
                            style={{
                                position: 'relative',
                                overflow: 'hidden',
                                display: 'inline-block',
                                cursor: 'pointer',
                            }}
                        >
                            <a
                                href={urlFor(animal.image).url()}
                                data-lightbox="image"
                                data-title={animal.name}
                                style={{
                                    display: 'block',
                                    position: 'relative',
                                    overflow: 'hidden',
                                }}
                            >
                                <img
                                    src={urlFor(animal.image).width(800).url()}  // Higher resolution for initial display
                                    alt={animal.name}
                                    style={{
                                        width: '100%',
                                        height: 'auto',
                                        marginTop: '1rem',
                                        borderRadius: '8px',
                                        transition: 'transform 0.3s ease',
                                    }}
                                    loading="lazy"  // Optional: Lazy load for better performance
                                />
                                <div
                                    style={{
                                        content: '""',
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                        width: '100%',
                                        height: '100%',
                                        backgroundColor: 'rgba(0, 0, 0, 0.4)',
                                        opacity: 0,
                                        transition: 'opacity 0.3s ease',
                                        zIndex: 1,
                                    }}
                                    className="hover-overlay"
                                ></div>
                                <h2
                                    style={{
                                        position: 'relative',
                                        zIndex: 2,
                                        color: 'white',
                                        fontSize: '1.25rem',
                                        fontWeight: '600',
                                        textAlign: 'center',
                                    }}
                                >
                                    {animal.name}
                                </h2>
                                {animal.details && animal.details.length > 0 && (
                                    <div style={{ position: 'relative', zIndex: 2 }} className="prose max-w-none">
                                        <h2 className="text-xl font-semibold">{animal.name}</h2>
                                        <PortableText blocks={animal.details} />
                                    </div>
                                )}
                            </a>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default WildlifeLandscapesSection;
