import React from "react";

const Footer = () => {
    return (
        <section className="footer">
            <p>Copyright &copy; 2024 Clubhouse Links Corp</p>
        </section>
    );
};

export default Footer;
