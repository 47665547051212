import React, { useState, useEffect } from "react";
import { sanityFetch, urlFor } from '../sanity/client';

const FAMILY_QUERY = `*[_type == "slider"] | order(orderNumber asc) {_id, name, image, orderNumber}`;

const Slider = () => {
    const [sanitySlides, setSanitySlides] = useState([]);
    const [currentSlide, setCurrentSlide] = useState(0);

    // Fetch images from Sanity
    useEffect(() => {
        const fetchSlides = async () => {
            const slides = await sanityFetch({ query: FAMILY_QUERY });
            setSanitySlides(slides);
        };
        fetchSlides();
    }, []);

    // Automatically change slide every 3 seconds
    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentSlide((prevSlide) => (prevSlide + 1) % sanitySlides.length);
        }, 3000); // Display time for each slide
        return () => clearInterval(interval);
    }, [sanitySlides.length]);

    // Styles for the slider
    const sliderContainerStyle = {
        position: "relative",
        width: "100%",
        height: "100vh",
        overflow: "hidden",
    };

    const slideStyle = (index) => ({
        width: "100%",
        height: "100vh",
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundImage: `url(${urlFor(sanitySlides[index]?.image).width(1600).url()})`,
        opacity: index === currentSlide ? 1 : 0,
        animation: index === currentSlide ? "zoomOut 3s linear forwards" : "none",  // Apply zoom-out only to active slide
        transition: "opacity 1s ease-in-out", // Smooth fade effect
        position: "absolute",
        top: 0,
        left: 0,
    });

    const infoStyle = {
        position: "absolute",
        zIndex: 10,
        top: "50%",
        right: "60px",
        transform: "translateY(-50%)",
        color: "#fff",
        textAlign: "right",
    };

    const headingStyle = {
        fontSize: "48px",
        color: "#fff",
        fontWeight: "700",
        marginBottom: "15px",
    };

    const buttonStyle = {
        marginTop: "30px",
        backgroundColor: "#fff",
        color: "#000",
        padding: "10px 20px",
        textDecoration: "none",
        fontSize: "15px",
    };

    return (
        <div className="slider" style={sliderContainerStyle}>
            {sanitySlides.map((slide, index) => (
                <div key={index} className="slide" style={slideStyle(index)}></div>
            ))}
            <div className="info" style={infoStyle}>
                <h1 style={headingStyle}>Photography By Michael T</h1>
                <p>Explore our beautiful gallery of images</p>
                {/* <a href="#featured" style={buttonStyle}>Learn More</a> */}
            </div>
        </div>
    );
};

// Add the zoom-out keyframe animation
const styles = document.createElement("style");
styles.innerHTML = `
@keyframes zoomOut {
    0% { transform: scale(1.2); }
    100% { transform: scale(1); }
}
`;
document.head.appendChild(styles);

export default Slider;
