import React, { useEffect, useState } from 'react';
import { sanityFetch, urlFor } from '../sanity/client';
import PortableText from "@sanity/block-content-to-react";
import 'lightbox2/dist/css/lightbox.min.css';
import 'lightbox2/dist/js/lightbox-plus-jquery.min.js';
import './sectionstyles.css';

const ABOUT_QUERY = `*[_type == "about"] | order(orderNumber asc) {_id, name, headline, image, details, orderNumber}`;

const AboutSection = () => {
    const [aboutItems, setAboutItems] = useState([]);

    useEffect(() => {
        const fetchAboutItems = async () => {
            const data = await sanityFetch({ query: ABOUT_QUERY });
            setAboutItems(data);
        };
        fetchAboutItems();
    }, []);

    return (
        <section id="about" className="content-section">
            <div className="section-heading">
                <h1><br /><em>About</em></h1>
            </div>
            <div className="container">
                <div className="row">
                    {aboutItems.map((item) => (
                        <div key={item._id} className="col-xs-12 col-sm-4">
                            <div
                                className="box about_box_line card"
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'space-between',
                                    minHeight: '500px', // Fixed card height
                                    overflow: 'hidden',
                                }}
                            >
                                <div
                                    className="box-icon"
                                    style={{
                                        position: 'relative',
                                        overflow: 'hidden',
                                        height: '250px', // Set a fixed height for the image container
                                        width: '100%',
                                    }}
                                >
                                    <a href={urlFor(item.image).url()} data-lightbox="image" style={{ display: 'block', position: 'relative' }}>
                                        <img
                                            src={urlFor(item.image).width(800).url()}  // Higher resolution for sharper images
                                            alt={item.name}
                                            className="img-responsive"
                                            style={{
                                                width: '100%',
                                                height: '100%',  // Ensures image covers the container
                                                objectFit: 'cover',  // Keeps the image proportions while filling the area
                                            }}
                                        />
                                        <div
                                            style={{
                                                content: '""',
                                                position: 'absolute',
                                                top: 0,
                                                left: 0,
                                                width: '100%',
                                                height: '100%',
                                                backgroundColor: 'rgba(255, 255, 255, 0.4)',
                                                opacity: 0,
                                                transition: 'opacity 0.3s ease',
                                                zIndex: 1,
                                            }}
                                            className="hover-overlay"
                                        ></div>
                                    </a>
                                </div>
                                <div className="info" style={{ padding: '20px' }}>
                                    <div className="about_box_title">
                                        <h3>{item.name}</h3>
                                        <h4>{item.headline}</h4>
                                    </div>
                                    {item.details && (
                                        <p className="about_box_text">
                                            <PortableText blocks={item.details} />
                                        </p>
                                    )}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default AboutSection;
