import React from "react";
import logo from '../img/pbmtlogo1.jpg';
import { HashLink as Link } from 'react-router-hash-link';

const Sidebar = () => {
    return (
        <div className="sidebar-navigation hidden-sm hidden-xs" style={{ position: 'fixed' }}>
            <div style={{ paddingBottom: 0, paddingTop: '2px', marginBottom: '10px', backgroundColor: '#45489a' }}>
                <div>
                    <img
                        src={logo}
                        alt="Logo"
                        className="img-responsive"
                        style={{ width: '130px', height: 'auto', display: 'block', margin: '0 auto' }}
                    />
                </div>
            </div>
            <nav style={{ marginTop: 0 }}>
                <ul style={{ padding: '7px', margin: 0 }}>
                    <li style={{ marginBottom: '10px' }}><Link smooth to="#top">Home</Link></li>
                    <li style={{ marginBottom: '10px' }}><Link smooth to="#about">About</Link></li>
                    <li style={{ marginBottom: '10px' }}><Link smooth to="#featured">Awards</Link></li>
                    <li style={{ marginBottom: '10px' }}><Link smooth to="#projects">Family</Link></li>
                    <li style={{ marginBottom: '10px' }}><Link smooth to="#wildlife">Wildlife/Landscapes</Link></li>
                    <li><Link smooth to="#contact">Contact Michael T</Link></li>
                </ul>
            </nav>
        </div>
    );
};

export default Sidebar;
