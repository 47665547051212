import React, { useState } from "react";
import axios from "axios";

const ContactSection = () => {
    // State to store form data
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        subject: "",
        message: ""
    });

    // Handle form input changes
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value
        }));
    };

    // Submit function to send form data
    const handleSubmit = async (e) => {
        e.preventDefault();  // Prevent the default form submission behavior

        try {
            const response = await axios.post("/server/photography_by_michael_t_function/contact", formData);
            if (response.status === 200) {
                alert("Message sent successfully!");
                setFormData({ name: "", email: "", subject: "", message: "" });  // Clear the form
            } else {
                alert("Failed to send the message. Please try again.");
            }
        } catch (error) {
            console.error("Error sending contact form:", error);
            alert("An error occurred. Please try again.");
        }
    };

    return (
        <section id="contact" className="content-section">
            <div id="map">
                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d53500.0826783548!2d-96.7213056!3d33.062912000000004!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sus!4v1729720541707!5m2!1sen!2sus"
                    width="100%"
                    height="450"
                    frameBorder="0"
                    allowFullScreen
                    loading="lazy"
                    style={{ border: 0 }}
                    referrerPolicy="no-referrer-when-downgrade"
                ></iframe>
            </div>
            <div id="contact-content">
                <div className="section-heading">
                    <h1>Contact<br /><em>Michael T</em></h1>
                </div>
                <div className="section-content">
                    <form id="contact"  onSubmit={handleSubmit}>
                        <div className="row">
                            <div className="col-md-4">
                                <input
                                    style={{color:'black'}}
                                    name="name"
                                    type="text"
                                    className="form-control"
                                    placeholder="Your name..."
                                    value={formData.name}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <div className="col-md-4">
                                <input
                                    style={{color:'black'}}
                                    name="email"
                                    type="email"
                                    className="form-control"
                                    placeholder="Your email..."
                                    value={formData.email}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <div className="col-md-4">
                                <input
                                    style={{color:'black'}}
                                    name="subject"
                                    type="text"
                                    className="form-control"
                                    placeholder="Subject..."
                                    value={formData.subject}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <div className="col-md-12">
                                <textarea
                                    style={{color:'black'}}
                                    name="message"
                                    rows="6"
                                    className="form-control"
                                    placeholder="Your message..."
                                    value={formData.message}
                                    onChange={handleChange}
                                    required
                                ></textarea>
                            </div>
                            <div className="col-md-12">
                                <button type="submit" className="btn">Send Message Now</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </section>
    );
};

export default ContactSection;
