import React, { Component } from "react";
import { render } from "react-dom";
import ReactDOM from 'react-dom';
import './index.css';
import { HashRouter as Router, Routes, Route, useLocation } from "react-router-dom";
import HomePage  from './App';
import EventsDetailsPage from "./components/EventsDetailsPage";
import reportWebVitals from './reportWebVitals';



class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: "React"
        };
    }

render() {

    return (
        <Router>
            <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/events/:slug" element={<EventsDetailsPage />} />
            </Routes>
        </Router>
    )}
}
render(<App />, document.getElementById("root"));
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
