import React, { useState } from "react";
import logo from '../img/pbmtlogo1.jpg';
import 'bootstrap-icons/font/bootstrap-icons.css'; // Import Bootstrap Icons
import { HashLink as Link } from 'react-router-hash-link';

const TopBar = () => {
    const [open, setOpen] = useState(false);

    return (
        <div style={{ position: 'fixed', width: '100%', zIndex: 10 }}>
            <div
                className="topbar-container d-flex align-items-center justify-content-between p-3"
                style={{ backgroundColor: '#fff' }}
            >
                <img src={logo} alt="Logo" style={{ width: '80px', height: 'auto' }} />
                <button
                    className="navbar-toggler"
                    type="button"
                    onClick={() => setOpen(!open)}
                    aria-label="Toggle navigation"
                    style={{
                        border: 'none',
                        background: 'transparent',
                        fontSize: '1.5rem',
                    }}
                >
                    <i className="bi bi-list"></i> {/* Bootstrap hamburger icon */}
                </button>
            </div>
            {open && (
                <div className="menu" style={{ backgroundColor: '#f8f9fa', padding: '1rem' }}>
                    <ul style={{ listStyleType: 'none', padding: 0, }}>
                        <li style={{ marginBottom: '10px' }}><Link  smooth to="#top"><p style={{color:'#45489a'}}>Home</p></Link></li>
                        <li style={{ marginBottom: '10px' }}><Link smooth to="#about"><p style={{color:'#45489a'}}>About</p></Link></li>
                        <li style={{ marginBottom: '10px'}}><Link smooth to="#featured"><p style={{color:'#45489a'}}>Awards</p></Link></li>
                        <li style={{ marginBottom: '10px'}}><Link smooth to="#projects"><p style={{color:'#45489a'}}>Family</p></Link></li>
                        <li style={{ marginBottom: '10px' }}><Link smooth to="#wildlife"><p style={{color:'#45489a'}}>WildLife Landscapes</p></Link></li>
                        <li><Link smooth to="#contact"><p style={{color:'#45489a'}}>Contact Michael T</p></Link></li>
                    </ul>
                </div>
            )}
        </div>
    );
};

export default TopBar;
